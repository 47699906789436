import { numericBadge } from '@/components/numeric-badge/numeric-badge.css.ts';
import { ComponentPropsWithoutRef, FC } from 'react';
import { clsx } from 'clsx';

export const NumericBadge: FC<
  ComponentPropsWithoutRef<'div'> & { title?: string; important?: boolean }
> = ({ children, title, important, className }) => {
  return (
    <div
      className={clsx(numericBadge, className)}
      title={title}
      data-important={important ? '' : undefined}
    >
      {children}
    </div>
  );
};
